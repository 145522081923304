<template>
  <CSidebar 
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand to="/dashboard">
    <div class="c-sidebar-brand-minimized">
      <img src="../../src/assets/icons/SCGP_Master Logo_Primary Logo-01.png"  height="20px"   />
    </div>
    <div class="c-sidebar-brand-full" >
      <img src="../../src/assets/icons/SCGP_Master Logo_Primary Logo-01.png"  height="60px"  />
    </div>
    </CSidebarBrand>
    <CRenderFunction flat :contentToRender="$options.nav"/>
    <CSidebarMinimizer 
      class="c-d-md-down-none" 
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<style>
/* สีหลัก */
.c-sidebar {
    background: #c8ced3 !important;
}

/* สี text เมื่อ In active */
.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
    color: #2f353a !important;
    background: transparent !important; 
}

/* สี text เมื่อ active */
.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
    color: #000000 !important;
    background: #ced2d8 !important;
}

/* สี icon เมื่อ active */
.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: #000000;
}

/* สี icon เมื่อ In active */
.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: rgb(0 0 0 / 50%);
}

/* สีเมื่อ hover */
@media (hover: hover), not all{
  .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #000000 !important;
    background: #ced2d8 !important;
  }
  .c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
    color: #000000 !important;
  }
}
</style>

<script>
import nav from './_nav'
export default {
  name: 'TheSidebar',
  nav,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>
