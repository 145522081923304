<template>
  <div>
    <!-- Modal  start-->
    <CModal
      :show.sync="showModal"
      :close-on-backdrop="false"
      title="Modal title 2"
      size="xl"
      color="primary"
      :class="ModalName"    
    >
      <template #header>
        <h6 class="modal-title">Insight</h6>
        <CButtonClose @click="ChangeModal()" id="btnCloseModal" class="text-white" v-if="viewer === false" />
      </template>

      <div v-if="isviewDocumentStatus">
        <CRow>
          <CCol>
            <h5 class="subhead-modal">
              Creator detail
            </h5>
          </CCol>
          <CCol>
            <div class="subhead-modal-action">
              <CLink
                class="card-header-action btn-minimize"
                @click="formCollapsed = !formCollapsed"
              >
                <CIcon name="cil-chevron-bottom" v-if="formCollapsed" />
                <CIcon name="cil-chevron-top" v-else />
              </CLink>
            </div>
          </CCol>
        </CRow>
        <CCollapse :show="formCollapsed">
          <CRow>
            <CCol md="6">
              <CInput
                label="Creator"
                horizontal
                readonly="readonly"
                v-model="Creator"
              />
              <input type="hidden" v-model="CreateUser" />
            </CCol>
            <CCol md="6">
              <CInput
                label="Create Date"
                horizontal
                readonly="readonly"
                v-model="CreateDate"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol md="6">
              <CInput
                label="E-mail"
                horizontal
                readonly="readonly"
                v-model="Email"
              />
            </CCol>
            <CCol md="6">
              <CInput
                label="Business unit"
                horizontal
                readonly="readonly"
                v-model="CreatorBU"
              />
            </CCol>
          </CRow>
        </CCollapse>

        <hr />
      </div>

      <div>
        <CTabs id="InsightTab">
          <CTab
            :title="'Insight ' + (key + 1)"
            v-for="(lenobj, key) in InsightList"
            :key="lenobj.TabId"
            class="usingTabs"
            :id="'InsightTab' + (key + 1)"
          >
            <div class="action-right" >
              <CButton
                  class="text-right"
                  size="sm"
                  v-c-tooltip="{content: 'Copy this insight.', placement: 'top'}"
                  v-on:click="newLenTab(lenobj.TabId)"
                  v-if="viewmode === 'create'"
                  style="background-color: #ebedef;"
                >
                <CIcon name="cil-copy" />
              </CButton>
              &nbsp;
              <CButton 
                color="danger"
                class="text-right"
                size="sm"
                v-c-tooltip="{content: 'Delete this insight.', placement: 'top'}"
                v-on:click="closeLenTab(lenobj.TabId)"
                v-if="key > 0"
              >
              <CIcon name="cil-x" />
              </CButton>
            </div>
            <!--<div class="action-right" v-else>
              <br />
            </div>-->

            <CRow>
              <CCol md="6">
                <CRow>
                  <h5 class="subhead-modal pl-3">
                    Market / Customer
                  </h5>
                </CRow>
                <CRow>
                  <CCol class="form-group form-row">
                    <div class="col-md-3">
                      <label class="">For Which Industry <span class="error">*</span></label>
                    </div>
                    <div class="col-md-9 pr-0">
                      <v-select
                        :ref="'RefVSelect' + key"
                        v-model="lenobj.InsightCusIndustry"
                        :value.sync="lenobj.InsightCusIndustry"
                        :options="IndustryOptions"
                        placeholder="-- Select --"
                        @input="IndustryOnChange($event, lenobj.TabId)"
                        :disabled="disabled"
                        required
                      />
                      <div class="invalid-feedback">Example invalid select feedback</div>
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol class="form-group form-row">
                    <div class="col-md-3">
                      <label class="">Segment </label>
                    </div>
                    <div class="col-md-9 pr-0">
                      <v-select
                        v-model="lenobj.InsightCusSegment"
                        :value.sync="lenobj.InsightCusSegment"
                        :options="lenobj.SegmentOptions"
                        placeholder="-- Select --"
                        @input="SegmentOnChange($event, lenobj.TabId)"
                        :disabled="disabled"
                      />
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol class="form-group form-row">
                    <div class="col-md-3">
                      <label class="">Sub Segment </label>
                    </div>
                    <div class="col-md-9 pr-0">
                      <v-select
                        v-model="lenobj.InsightCusSubSegment"
                        :value.sync="lenobj.InsightCusSubSegment"
                        :options="lenobj.SubSegmentOptions"
                        placeholder="-- Select --"
                        :disabled="disabled"
                      />
                    </div>
                  </CCol>
                </CRow>
              </CCol>

              <CCol md="6">
                <CRow>
                  <h5 class="subhead-modal  pl-3">
                    Source
                  </h5>
                </CRow>

                <CRow>
                  <CCol class="form-group form-row">
                    <div class="col-md-3">
                      <label class="">Source of Insight <span class="error">*</span></label>
                    </div>
                    <div class="col-md-9 pr-0">
                      <v-select
                        :ref="'RefVSelectSourceOfInsight' + key"
                        v-model="lenobj.InsightSourceIndustry"
                        :value.sync="lenobj.InsightSourceIndustry"
                        :options="SourceIndustryOptions"
                        placeholder="-- Select --"
                        :disabled="disabled"
                        required
                      />
                    </div>
                  </CCol>
                </CRow>

                <CTextarea
                  :ref="'TextAreaSourceDetail' + key"
                  label="Source Detail"
                  rows="3 "
                  horizontal
                  v-model="lenobj.InsightSourceDetail"
                  :disabled="disabled"
                  placeholder="e.g. Customer name and contact person / URL website / Where did you observe?"
                  required
                  was-validated
                />
                
              </CCol>
            </CRow>
            <br />
            <CRow>
              <h5 class="subhead-modal pl-3">
                Insight Detail
              </h5>
            </CRow>

            <CRow>
              <CCol md="6">
                <CInput
                  label="Insight No."
                  horizontal
                  v-model="lenobj.InsightNo"
                  readonly
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Status"
                  horizontal
                  v-model="lenobj.InsightStatus"
                  readonly
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="6" class="form-group form-row">
                <div class="col-md-3">
                  <label class="">4 Lens <span class="error">*</span></label>
                </div>
                <div class="col-md-9 pr-0">
                  <v-select
                    :ref="'RefVSelect4Lens' + key"
                    v-model="lenobj.InsightLens"
                    :value.sync="lenobj.InsightLens"
                    :options="LensOptions"
                    placeholder="-- Select --"
                    :disabled="disabled"
                    @input="InsightLensOnChange($event)"
                    required
                  />
                </div>
                <div class="col-md-3">
                </div>
                <!-- <div class="col-md-9">
                  <label class="LensRemark" style="color:#858585">{{LensRemark}}<span class="LensRemark2" v-if="LensRemark2!='' && LensRemark3!=''"><br/><u>{{LensRemark2}}</u> {{LensRemark3}}</span></label>                 
                </div> -->
              </CCol>
              <CCol md="6" class="pl-4">
                <CTextarea
                  :ref="'TextAreaDetail' + key"
                  label="Detail"
                  rows="3"
                  horizontal
                  v-model="lenobj.InsightDetail"
                  :disabled="disabled"
                  :placeholder="LensRemark2 +' ' + LensRemark3"
                  required
                  was-validated
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="6">
                <CInputFile multiple custom label="Attach files" horizontal 
                :description="'(*Only .pdf, .doc, .xls, .ppt, .jpg, .png maximum size 5 MB)'"
                v-on:change="fileChange($event, lenobj.TabId)"
                :ref="'file'+lenobj.TabId"
                 :disabled="disabled" />
                
                <div class="cpl-10" v-for="file in lenobj.fileExists" :key="file.AttachmentID">
                  <div v-if="!file.IsDelete">
                    <CLink class="text-primary" @click="OpenFile(file.AttachmentName, true)">
                      {{ file.AttachmentName.split("/")[1] }}
                    </CLink>
                    <CButton class="btn-sm" @click="ConfirmDeleteFile(file)" v-if="viewmode !== 'view'" color="danger">
                      Delete
                    </CButton>
                  </div>
                </div>

                <div class="cpl-10" v-for="(file, key) in lenobj.files" :key="file.name">
                  <CLink class="text-primary" @click="OpenFile(file)">
                      {{ file.name }}
                    </CLink>
                  <CButton color="danger" size="sm" @click="ConfirmDeleteFileBeforeSave(key, lenobj.TabId)">
                      Delete
                  </CButton>
                </div>
              </CCol>
              <CCol md="6" class="form-group form-row" v-c-tooltip="{content: 'BU ที่เกี่ยวข้องกับ Insight นี้', placement: 'top'}">
                <div class="col-md-3">
                  <label class="">Related business unit<span class="error">*</span></label>
                </div>
                <div class="col-md-9 pr-0">
                  <v-select
                    :ref="'RefVSelectBU' + key"
                    v-model="lenobj.BU"
                    :value.sync="lenobj.BU"
                    :options="buOptions"
                    placeholder="-- Select --"
                    :disabled="disabled"
                    required
                  />
                </div>
              </CCol>
            </CRow>

            <!--<br />
            <CButton
              size="lg"
              color="primary"
              block
              v-on:click="newLenTab(null)"
              v-if="viewmode === 'create'"
            >
              + Add more insight</CButton
            >
            <CButton
              size="lg"
              color="primary"
              block
              v-on:click="newLenTab(lenobj.TabId)"
              v-if="viewmode === 'create'"
            >
              Copy this insight</CButton
            >-->
          </CTab>

          <div
            v-on:click="newLenTab(null)"
            v-if="viewmode === 'create' && IsAddNewTab"
          >
            <CTab title="+" class="linknewtab"> </CTab>
          </div>
        </CTabs>
      </div>

      <template #footer>

          <CButton @click="K2Action('Approve')" color="success" v-if="userRole === 'Admin' && viewmode !== 'create' && item !== null  && item !== undefined && (viewmode === 'edit' && item.InsightStatus === 'Manager' || viewmode === 'view' && item.InsightStatus === 'Manager')">
            Approve
          </CButton>
          <CButton @click="K2Action('Reject')" style="background-color:#f7cc54" v-if="userRole === 'Admin' && viewmode !== 'create' && item !== null  && item !== undefined && (viewmode === 'edit' && item.InsightStatus === 'Manager' || viewmode === 'view' && item.InsightStatus === 'Manager')">
            Reject
          </CButton>
          <!-- <CButton @click="ChangeModal()" color="dark" v-if="userprofile.Role == 'admin'">
            Terminate
          </CButton> -->
          <CButton @click="K2Action('Submit')" v-if="viewmode === 'create' || (viewmode !== 'view' &&  (item !== null  && item !== undefined && item.InsightStatus !== 'Completed' &&  item.InsightStatus !== 'Manager'))" color="submit-custom">
            Submit
          </CButton>
          <CButton @click="DeleteData()" color="danger" v-if="viewmode !== 'create' && viewmode !== 'view' && (item !== null  && item !== undefined && item.InsightStatus === 'Draft')">
            Delete
          </CButton>
          <CButton @click="SaveData('Save')" color="primary" v-if="viewmode === 'create' || (viewmode === 'edit' && item !== null  && item !== undefined && item.InsightStatus === 'Draft')">
            Save Draft
          </CButton>
          <CButton @click="ChangeModal()" id="btnCancel" color="secondary" v-if="viewer === false">
            Cancel
          </CButton>

      </template>
    </CModal>
    <!-- Modal  end-->

    <IdeaModal
      :showModal.sync="ShowIdeaModal"
      @IdeaModal-updated="ShowIdeaModal = $event"
      :insightDataSelected="InsightSelected"
      @insightListSelected-updated="InsightSelected = $event"
      :viewmode="ideaMode"
    />
    <CModal
      :show.sync="previewImgModal"
      :close-on-backdrop="false"
      title="Preview"
      size="lg"
      color="primary"
      centered
    >
      <template #header>
        <h6 class="modal-title">Preview</h6>
        <CButtonClose
          @click="previewImgModal = false"
          class="text-white"
        />
      </template>
      <CRow>        
        <CCol class="form-group form-row">
          <div id="preview">
            <img v-if="urlImage" :src="urlImage" />
          </div>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="previewImgModal = false" color="secondary">Close</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="ConfirmIdeaModal"
      :close-on-backdrop="false"
      title="Add Idea ?"
      color="primary"
      centered
    >
      Do you want to create idea from this insight ?
      <template #header>
        <h6 class="modal-title">Add Idea</h6>
        <CButtonClose @click="ConfirmIdeaModal = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="ConfirmIdeaModal = false" color="secondary"
          >No</CButton
        >
        <CButton @click="ConfirmAddIdea()" color="primary">Yes</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="SelectInsightModal"
      :close-on-backdrop="false"
      title="Select Insight"
      size="xl"
      color="primary"
    >
      <CDataTable
        :items="InsightListSubmit"
        :fields="fields"
        table-filter
        items-per-page-select
        :items-per-page="5"
        hover
        sorter
        pagination
      >
        <template #selectbox="{item}">
          <td>
            <CInputCheckbox
              :value="item.TabId"
              :name="`Option ${item.TabId}`"
              @click="UpdateIsSelected(item.InsightNo, $event)"
            />
          </td>
        </template>
        <template #status="{item}">
          <td>
            <CBadge :color="getBadge(item.status)">
              {{ item.status }}
            </CBadge>
          </td>
        </template>
      </CDataTable>
      <template #footer>
        <CButton @click="SelectInsightModal = false" color="secondary"
          >No</CButton
        >
        <CButton @click="AddIdea()" color="primary">Yes</CButton>
      </template>
    </CModal>

    <IdeaModal
      :showModal.sync="ShowIdeaModal"
      @IdeaModal-updated="ShowIdeaModal = $event"
      :insightDataSelected="InsightSelected"
      @insightListSelected-updated="InsightSelected = $event"
      :viewmode="ideaMode"
    />
    <CModal
      :show.sync="confirmSubmitInsightModal"
      :close-on-backdrop="false"
      title="Submit Insight?"
      color="primary"
      centered
    >
    Do you want to create idea from this insight?
      <template #header>
        <h6 class="modal-title">Submit Insight</h6>
        <CButtonClose
          @click="confirmSubmitInsightModal = false"
          class="text-white"
        />
      </template>
      <template #footer>
        <CButton @click="CancelSubmit()" color="secondary">No</CButton>
        <CButton @click="SaveData('Submit')" color="primary">Yes</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="confirmApproveInsightModal"
      :close-on-backdrop="false"
      title="Approve Insight?"
      size="sm"
      color="primary"
      centered
    >
      Would you like to approve insight?
      <template #header>
        <h6 class="modal-title">Approve Insight</h6>
        <CButtonClose
          @click="confirmApproveInsightModal = false"
          class="text-white"
        />
      </template>
      <template #footer>
        <CButton @click="CancelApprove()" color="secondary">No</CButton>
        <CButton @click="SaveData('Approve')" color="primary">Yes</CButton>
      </template>
    </CModal>

    <RejectModal 
      :showModal.sync="showRejectModal"
      @RejectModal-Updated="showRejectModal = $event"
      :rejectModel.sync="rejectModel"
      @close-modal="ChangeModal(true)"
    />
    <Spinner :is-show="isSpinner = hideLoader"/>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import IdeaModal from "../idea/IdeaModal";
import store from "../../store";
import axios from "axios";
import { uuid } from "vue-uuid";
import RejectModal from "../RejectModal";
import Spinner from "../../components/Spinner";

var URL_GetAll = store.getters.URL + "/api/IdeationInsight/getall";
var URL_GetByID = store.getters.URL + "/api/IdeationInsight/getbyid";
var URL_Insert = store.getters.URL + "/api/IdeationInsight/insert";
var URL_InsertList = store.getters.URL + "/api/IdeationInsight/insertlist";
var URL_Update = store.getters.URL + "/api/IdeationInsight/update";
var URL_Delete = store.getters.URL + "/api/IdeationInsight/delete";
var URL_Upload = store.getters.URL + "/api/IdeationInsight/uploadfile";
var URL_GetFilesByDoc = store.getters.URL + "/api/IdeationAttachment/getbydocid";
var URL_OpenFile = store.getters.URL + "/api/IdeationAttachment/openfile";
var URL_GetBu = store.getters.URL + "/api/MasterBu/getall";

const today = new Date();

const fields = [
  {
    key: "selectbox",
    label: "Select",
    _style: "width:1%",
    sorter: false,
    filter: false
  },
  { key: "InsightNo", label: "insight No" },
  { key: "InsightDetail", label: "insight Detail" }
];

export default {
  name: "InsighModal",
  components: {
    vSelect,
    IdeaModal,
    RejectModal,
    Spinner
  },
  data() {
    return {
      ModalName:"InsightModal",
      isviewDocumentStatus: true,
      formCollapsed: true,
      ConfirmIdeaModal: false,
      SelectInsightModal: false,
      ShowIdeaModal: false,
      fields,

      InsightSelected: [],

      CreateUser: "",
      Creator: "",
      CreateDate: "",
      Email: "",
      CreatorBU: "",
      BU:"",
      IsAddNewTab: true,
      ideaMode : "create",
      urlImage:null,

      buOptions: [{ value: null, label: "--Select--" }],
      buList: [],
      InsightList: [],
      InsightListSubmit :[],
      InsightListTemp:[],
      IndustryOptions: [{ value: null, label: "--Select--" }],
      SegmentList: [],
      SegmentOptions: [{ value: null, label: "--Select--" }],
      SubSegmentList: [],
      SubSegmentOptions: [{ value: null, label: "--Select--" }],
      SourceIndustryOptions: [{ value: null, label: "--Select--" }],
      LensOptions: [{ value: null, label: "--Select--" }],

      // userprofile: JSON.parse(localStorage.getItem("userprofile")),
      // userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      userprofile: JSON.parse(localStorage.getItem("userprofile")),
      userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      previewImgModal:false,
      confirmSubmitInsightModal: false,
      confirmApproveInsightModal: false,
      submitObj: [],
      k2Obj: [],
      insightProcessID:"",
      disabled: false,
      insightStatus: "",
      showRejectModal: false,
      rejectModel: {
        DocumentNo: "",
        ProcessID: "",
        RejectLevel: "",
        RejectUser: "",
        RejectComment: ""
      },
      insightNo: "",
      isSpinner:false,
      hideLoader:false,
      LenList:[],
      LensRemark:"",
      LensRemark2:"",
      LensRemark3:"",
    };
  },
  props: {
    showModal: Boolean,
    item: Object,
    viewmode: {
      type: String,
      default: "view",
      required: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    
    if (this.viewmode === "create") {
      this.CreateUser = this.userprofile.Username;
      this.Creator = this.userprofile.DisplayName;
      this.Email = this.userprofile.Email;
      this.CreatorBU = this.userprofile.BU;
      this.CreateDate = new Date()
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "/")
        .replace("T", " ");
      this.newLenTab(null);
      this.GoToLastTab();
    }

    this.$nextTick(() => {
      this.GetBu();
      this.LoadIndustry();
      this.LoadSegment();
      this.LoadSubSegment();
      this.LoadSourceIndustry();
      this.LoadLens();
    })
    // setTimeout(
    //   function() {
    //     //console.log("Load data");
    //     this.GetBu();
    //     this.LoadIndustry();
    //     this.LoadSegment();
    //     this.LoadSubSegment();
    //     this.LoadSourceIndustry();
    //     this.LoadLens();
    //   }.bind(this),
    //   100
    // );
  },
  methods: {
    SetViewMode(disabled) {
      this.disabled = disabled;

      let elements = document.querySelectorAll('input,textarea');
      elements.forEach((element) => {
        // //console.log(element.id);
        if (element.id !== "txtRejectComment") element.disabled = disabled;
        // element.disabled = disabled;
      });
    },
    LoadListAll: function() {
      //console.log("LoadListAll",URL_GetAll);
      axios.get(URL_GetAll).then(response => {
        //console.log("response",response.data);
      });
    },
    LoadById(Id) {
      //console.log("LoadById", Id);
      axios
        .get(URL_GetByID, {
          params: {
            uid: Id
          }
        })
        .then(response => {
          if (response !== null) {
            //console.log("response",response.data);

            var NewObj = this.NewObj(response.data);

            this.CreateUser = this.userprofile.CreateByUser;
            this.Creator = NewObj.InsightCreator;
            this.CreateDate = NewObj.InsightCreateDate.substring(0,10).replaceAll("-", "/");
            this.Email = NewObj.InsightCreatorEmail;
            this.CreatorBU = NewObj.InsightCreatorBU;
            this.BU = NewObj.BU;

            NewObj.fileExists = this.LoadFilesByID(NewObj);
          }
        });
    },
    LoadFilesByID(Obj) {
      //console.log("LoadFilesByID",Obj);
      var fileExists = [];
      axios
        .get(URL_GetFilesByDoc, {
          params: {
            DocumentID: Obj.InsightNo,
            Path: store.getters.URL
          }
        })
        .then(response => {
          if (response !== null) {
            //console.log("LoadFilesByID",response.data);
            Obj.fileExists = response.data;
            //let Path = store.getters.URL + "/" + response.Path;
            //console.log("Path",Path);
            //Obj.Path = Path;
          }
        })
        .finally(() => {
          //console.log("InsightList",this.InsightList);
          let currentIndex = this.InsightList.findIndex(
            t => t.InsightNo == Obj.InsightNo
          );
          if (currentIndex > -1) {
            this.InsightList.splice(currentIndex, 1);
          }

          this.InsightList.push(Obj);

          setTimeout(
            function() {
              let lastusingTabs = document.querySelector(
                "#InsightTab" + this.InsightList.length + " a"
              );

              //console.log("lastusingTabs = ", lastusingTabs);

              if (lastusingTabs !== null) {
                //console.log("click");
                lastusingTabs.click();
              }
            }.bind(this),
            500
          );
        });
    },
    OpenFile(file, isExist) {
      
      if(isExist)
      {
        axios
          .get(URL_OpenFile + `?filename=${file}`,{headers: {"responseType": "arraybuffer"}})
          .then((response) => {
            if (response.status === 200) {
              let result = this.CheckOpenFile(response.data, isExist) ;//global Func
              if(result.isImage)
              {
                this.urlImage = result.urlImage;
                this.previewImgModal = true;
              }

            } else {
              this.$_toast_Noti("error", "error", response.data.Message);
            }
          })
          .catch((error) => {
            this.$_toast_Noti("error", "error", error);
          });
      }
      this.urlImage = null;
      let result = isExist? this.CheckOpenFile(null) : this.CheckOpenFile(file);//global Func
      if(result.isImage)
      {
        this.urlImage = result.urlImage;
        this.previewImgModal = true;
      }
      // if(isNotExist){
      //  // console.log('not exists');
      //   let currentIndex = this.InsightList.findIndex(t => t.TabId == TabId);
      //   if (currentIndex > -1) {
      //    // console.log('if 1', currentIndex, this.InsightList);
      //     if (this.InsightList[currentIndex].files.findIndex((f) => f.name == filename) > -1)
      //     {
      //       //console.log(this.InsightList[currentIndex].files);
      //       const file = this.InsightList[currentIndex].files[this.InsightList[currentIndex].files.findIndex((f) => f.name == filename)];
      //       this.urlImage = URL.createObjectURL(file);
      //       fileExtension = file.name.split('.').pop();
      //     }
      //     if(this.validateFileImageExtention(fileExtension)){
      //       this.previewImgModal = true;
      //     }
      //     else{
      //       window.open(this.urlImage, '_blank');
      //     }
      //   }        
      // }
      // else{
      //   window.open(URL_OpenFile + "?filename=" + filename);
      // }
      
    },
    fileChange(fileList, TabId) {
      let currentIndex = this.InsightList.findIndex(t => t.TabId == TabId);
      if (currentIndex > -1) {
        var IsValidate = true;
        Array.from(fileList).forEach(file => {
            if(file.size > 5242880){
              this.$_toast_Noti("error", "error", "Cannot upload file more than 5 MB");
              IsValidate = false;
            }
        });
        // fileList.forEach(function (file) {
        //     if(file.size > 5242880){
        //       this.$_toast_Noti("error", "error", "Cannot upload file more than 5 MB");
        //       IsValidate = false;
        //     }
        // }.bind(this));
        if(IsValidate){
          // this.InsightList[currentIndex].files = fileList;
          // fileList.forEach((file) => {
          //   if (this.InsightList[currentIndex].files.findIndex((f) => f.name == file.name) === -1) {
          //     this.InsightList[currentIndex].files.push(file);
          //   }
          // });
          Array.from(fileList).forEach(file => {
            if (this.InsightList[currentIndex].files.findIndex((f) => f.name == file.name) === -1) {
              this.InsightList[currentIndex].files.push(file);
            }
          });
          document.getElementById(this.$refs['file'+TabId][0].safeId).value = null;

        }

         //console.log("files", this.InsightList[currentIndex].files);
         //console.log("files 1", this.InsightList[currentIndex]);
      }
    },
    NewFile(files){
      var newFile = [];
      files.forEach(file => {
        newFile.push(file)
      });
      return newFile;
    },
    NewObj(Obj) {

      //console.log("Obj",Obj);

      let SegmentFilterOption = [{ value: null, label: "--Select--" }];
      let SubSegmentFilterOption = [{ value: null, label: "--Select--" }];

      let BUValue = null;
      let IndustryValue = null;
      let SegmentValue = null;
      let SubSegmentValue = null;
      let SourceIndustryValue = null;
      let LensValue = null;

      if (Obj !== null) {

        if(Obj.BU !== null && Obj.BU !== undefined){
          BUValue = Obj.BU;
          if(Obj.BU.label !== undefined){
            BUValue = Obj.BU.label;
          }
        }
       // console.log("BUValue",BUValue);

        if(Obj.InsightCusIndustry !== null){
          IndustryValue = Obj.InsightCusIndustry;
          if(Obj.InsightCusIndustry.value !== undefined){
            IndustryValue = Obj.InsightCusIndustry.value;
          }
        }

        if(Obj.InsightCusSegment !== null){
          SegmentValue = Obj.InsightCusSegment;
          if(Obj.InsightCusSegment.value !== undefined){
            SegmentValue = Obj.InsightCusSegment.value;
          }
        }

        if(Obj.InsightCusSubSegment !== null){
          SubSegmentValue = Obj.InsightCusSubSegment;
          if(Obj.InsightCusSubSegment.value !== undefined){
            SubSegmentValue = Obj.InsightCusSubSegment.value;
          }
        }

        if(Obj.InsightSourceIndustry !== null){
          SourceIndustryValue = Obj.InsightSourceIndustry;
          if(Obj.InsightSourceIndustry.value !== undefined){
            SourceIndustryValue = Obj.InsightSourceIndustry.value;
          }
        }

        if(Obj.InsightLens !== null){
          LensValue = Obj.InsightLens;
          if(Obj.InsightLens.value !== undefined){
            LensValue = Obj.InsightLens.value;
          }
        }

        var buFilter = this.buList.filter(function(x) {
          return x.IndustryID === BUValue;
        });

        var SegmentFilter = this.SegmentList.filter(function(x) {
          return x.SegmentID === SegmentValue;
        });

        //console.log("SegmentFilter",SegmentFilter);

        SegmentFilter.forEach(function(data) {
          SegmentFilterOption.push({
            value: data.SegmentID,
            label: data.SegmentDesc
          });
        });

        var SubSegmentFilter = this.SubSegmentList.filter(function(x) {
          return x.SubSegmentID === SubSegmentValue;
        });

        //console.log("SubSegmentFilter",SubSegmentFilter);

        SubSegmentFilter.forEach(function(data) {
          SubSegmentFilterOption.push({
            value: data.SubSegmentID,
            label: data.SubSegmentDesc
          });
        });
      }

      var newFile = []
      newFile = Obj === null ? [] : Obj.files === null || Obj.files === undefined ? [] : this.NewFile(Obj.files);
      //var newFile = this.NewFile(Obj.files);

      var NewObj = {
          ActionUser: null,
          TabId : Obj === null ? null : (Obj.InsightNo === "" || Obj.InsightNo === null) ? Obj.TabId : Obj.InsightNo,
          InsightNo : Obj === null ? null : Obj.InsightNo,
          InsightCreator : Obj === null ? this.Creator : Obj.InsightCreator,
          InsightCreateDate : Obj === null ? this.CreateDate : Obj.InsightCreateDate,
          InsightCreatorEmail : Obj === null ? this.Email : Obj.InsightCreatorEmail,
          InsightCreatorBU : Obj === null ? this.CreatorBU : Obj.InsightCreatorBU,
          BU : Obj === null ? null : (this.buOptions[this.buOptions.findIndex(x => x.label === BUValue)]),

          InsightCusIndustry : Obj === null ? null : (this.IndustryOptions[this.IndustryOptions.findIndex(x => x.value === IndustryValue)]),
          InsightCusSegment : Obj === null ? null : (SegmentFilterOption[SegmentFilterOption.findIndex(x => x.value === SegmentValue)]),
          InsightCusSubSegment : Obj === null ? null : (SubSegmentFilterOption[SubSegmentFilterOption.findIndex(x => x.value === SubSegmentValue )]),

          InsightSourceIndustry : Obj === null ? null : this.SourceIndustryOptions[this.SourceIndustryOptions.findIndex(x => x.value === SourceIndustryValue) ],
          InsightSourceDetail : Obj === null ? null : Obj.InsightSourceDetail,
          InsightLens : Obj === null ? null : this.LensOptions[this.LensOptions.findIndex(x => x.value === LensValue ) ],
          InsightStatus : Obj === null ? "Draft" : Obj.InsightStatus,
          InsightDetail : Obj === null ? null : Obj.InsightDetail,
          InsightAttach : Obj === null ? null : Obj.InsightAttach,
          CreateByUser : Obj === null ? this.CreateUser : Obj.CreateByUser,
          FileNames: [],
          // files : Obj === null ? null : Obj.files,
          files: newFile,
          FlagDelete : false,
          fileExists : Obj === null ? [] : Obj.fileExists === null ? [] : Obj.fileExists,

          SegmentOptions : SegmentFilterOption,
          SubSegmentOptions : SubSegmentFilterOption,
          IsSelected : false,
          InsightProcessID : this.insightProcessID

      }

      this.insightStatus = NewObj.InsightStatus;

      //console.log("NewObj() = ",NewObj)

      // console.log("NewObj.files", NewObj.files);

      return NewObj;
    },
    ConvertDropdown(saveObj){
        //console.log("ConvertDropdown start",saveObj);
        saveObj = this.NewObj(saveObj);

        if (saveObj.BU != null && saveObj.BU != undefined) {
          if(saveObj.BU.value !== undefined){
            saveObj.BU = saveObj.BU.label;
          }
        }

        if (saveObj.InsightCusIndustry != null && saveObj.InsightCusIndustry !== undefined) {
          if(saveObj.InsightCusIndustry.value !== undefined){
            saveObj.InsightCusIndustry = saveObj.InsightCusIndustry.value;
          }
        }

        if (saveObj.InsightSourceIndustry != null && saveObj.InsightSourceIndustry !== undefined) {
          if(saveObj.InsightSourceIndustry.value !== undefined){
            saveObj.InsightSourceIndustry = saveObj.InsightSourceIndustry.value;
          }
        }

        if (saveObj.InsightCusSegment != null && saveObj.InsightCusSegment !== undefined) {
          if(saveObj.InsightCusSegment.value !== undefined){
            saveObj.InsightCusSegment = saveObj.InsightCusSegment.value;
          }
        }

        if (saveObj.InsightCusSubSegment != null && saveObj.InsightCusSubSegment !== undefined) {
          if(saveObj.InsightCusSubSegment.value !== undefined){
            saveObj.InsightCusSubSegment = saveObj.InsightCusSubSegment.value;
          }
        }

        if (saveObj.InsightLens != null && saveObj.InsightLens !== undefined) {
          if(saveObj.InsightLens.value !== undefined){
            saveObj.InsightLens = saveObj.InsightLens.value;
          }
        }
        return saveObj;
    },
    ChangeModal(isReject) {
      this.viewmode === "view" && this.SetViewMode(false);
      this.isshow = false;
      //console.log("isshow",this.isshow);
      this.InsightList = [];
      //this.item = null;
      this.newLenTab(null);
      if(isReject)
      {
        this.$emit("save-success");
      }
    },
    AddIdea() {
      this.SelectInsightModal = false;
      
      //console.log("AddIdea",this.InsightListTemp);
      this.InsightSelected = this.InsightListSubmit.filter(
        c => c.IsSelected === true
      );
      this.InsightListSubmit = [];
      //console.log("InsightSelected",this.InsightSelected);
      this.ShowIdeaModal = true;
    },
    ConfirmAddIdea() {
      //console.log("ConfirmAddIdea",this.InsightListTemp);
      this.hideLoader = true;
      setTimeout(
        function () {
          for(var i = 0; i < this.InsightListTemp.length ; i++)
          {
            this.InsightListTemp[i].InsightStatus = this.$store.getters.Status.WaitApprove;
          }
          this.InsightListSubmit = this.InsightListTemp
          this.ConfirmIdeaModal = false;
          this.SelectInsightModal = true;
          this.hideLoader = false;
        }.bind(this),
        2500
      );
    },
    ValidateStatus(status, action) {
      let result = null;
      if(status === 'Draft' && action === 'Submit')
      {
        result = 'Draft,Submit';
      }
      else
      {
        result = action === 'Save' ? 'Draft' : action;
      }

      return result;
    },
    SaveData(action) {
      //console.log("bu", this.BU);
      // if(action != 'Save' && (this.BU == "" || this.BU == null || this.BU == undefined))
      // {
      //   this.$_toast_Noti('error','error', 'Please select relation bu !');
      //   return;
      // }
      var modal = this.$el.getElementsByClassName(this.ModalName);
      var tabs = modal[0].getElementsByClassName("usingTabs");   
      var validation = true;

      Array.prototype.slice.call(tabs)
      .forEach(function (tab) {

        var forms = tab.querySelectorAll('[required="required"]');

        Array.prototype.slice.call(forms)
        .forEach(function (form) {

          var vs__dropdown = form.getElementsByClassName("vs__dropdown-toggle")[0];

          if(vs__dropdown !== "" && vs__dropdown !== null && vs__dropdown !== undefined){
            var vs__selected = vs__dropdown.getElementsByClassName("vs__selected")[0];
            if(vs__selected === "" || vs__selected === null || vs__selected === undefined){
              document.getElementById(tab.id).click();
              vs__dropdown.style.borderColor = "#ea5455";
              form.scrollIntoView(true);
              validation = false;
              return;
            }
            else{
              //console.log("vs__selected.text",vs__selected.textContent.trim());
              if(vs__selected.textContent.trim() === "-- Select --"){
                document.getElementById(tab.id).click();
                vs__dropdown.style.borderColor = "#ea5455";
                form.scrollIntoView(true);
                validation = false;
                return;
              }
              else{
                vs__dropdown.style.borderColor = "";
              }
              
            }
          }
          else if(form.value === "" || form.value === null || form.value === undefined){
            document.getElementById(tab.id).click();
            form.focus();
            validation = false;
            return;
          }
        });
      

      });


      if(validation){ 

          this.InsightListTemp = [];
          let saveList = [];
          this.InsightList.forEach(saveObj => {
            //console.log("saveObj",this.ConvertDropdown(saveObj));
            saveObj.ActionUser = this.userprofile.DisplayName
            //saveObj.BU = this.BU
            saveObj.InsightCreateDate = null;
            saveObj.InsightStatus = this.ValidateStatus(saveObj.InsightStatus, action);
            saveList.push(this.ConvertDropdown(saveObj));
          });
          //console.log("InsightList", this.InsightList);
          //console.log("saveList", saveList);
          if (this.viewmode === "create") {
            this.InsertList(saveList, action);
            this.confirmSubmitInsightModal = false;
            this.confirmApproveInsightModal = false;
          } 
          else {
            this.UpdateData(saveList[0], action);
            this.confirmSubmitInsightModal = false;
            this.confirmApproveInsightModal = false;
          }
          if(action == "Submit")
          {
            debugger
            this.ConfirmIdeaModal = true;
          }
        }
        else{
            //this.$_toast_Noti("error", "error", "1");
        }
    },
    InsertList: function(saveList) {
      this.hideLoader = true;

      axios
        .put(URL_InsertList, saveList)
        .then(response => {
          //console.log("response Insight", response);
          let formData = new FormData(); // Create FormData for upload file
          response.data.forEach(saveObj => {
            //console.log("saveObj", saveObj);
            // this.k2Obj.push({
            //   DocumentNO: saveObj.InsightNo,
            //   CreatorName: saveObj.InsightCreator,
            //   UserCreator: saveObj.CreateByUser,
            //   Action: "Submit"
            // });
            //this.InsightNoList.push({CreatorName: saveObj.InsightCreator});

            if(saveObj.IsError){
              this.$_toast_Noti("warn", "warn", saveObj.Message[0]);
            }
            let currentIndex = this.InsightList.findIndex(
              t => t.TabId == saveObj.TabId
            );

            //console.log("currentIndex",currentIndex);
            if (currentIndex > -1) {

              this.InsightList[currentIndex].InsightNo = saveObj.InsightNo;
              this.InsightList[currentIndex].InsightDetail = saveObj.InsightDetail;

              if (this.InsightList[currentIndex].files !== null && this.InsightList[currentIndex].files !== undefined) {
                this.InsightList[currentIndex].files.forEach(file => {
                  let filename = this.GenerateFileName(file.name); // Create file name
                  //saveObj.FileNames.push(filename); // Add file name to list
                  formData.append(saveObj.InsightNo, file, filename); // Add file for upload
                });
              }

              var temp = this.ConvertDropdown(this.NewObj(this.InsightList[currentIndex]));
              //console.log("temp",temp);
              this.InsightListTemp.push(temp);
            }
          });

          if (response.status === 200) {
            this.$emit("save-success");
            this.$_toast_Noti("success", "success", "");
          }

          // Upload File
          this.UploadFile(formData);
        })
        .catch(error => {
          //console.log("error", error);
          this.$_toast_Noti("error", "error", error);
        })
        .finally(() => {
          //this.InsightList = saveList;
          this.hideLoader = false;
          this.ChangeModal();
          // =====> K2
          // if (action === "Submit") {
          //   this.ConfirmK2Service(this.k2Obj);
          //   //submit k2//
          // }
          // else{
          //   this.ChangeModal();
          // }
          // =====> K2
        });
    },
    UpdateData: function(saveObj, action) {
      this.hideLoader = true;
      axios
        .post(URL_Update, saveObj)
        .then(response => {
          console.log("response update", response);
          //this.InsightObject = saveObj;
          if (response.status === 200) {
            this.$emit("save-success");
            this.$_toast_Noti("success", "success", "");

            if(response.data.IsError)
            {
              this.$_toast_Noti("warn", "warn", response.data.Message);
            }
          }
          
        })
        .catch(error => {
          //console.log("error", error);
          this.$_toast_Noti("error", "error", error);
        })
        .finally(() => {

          let currentIndex = this.InsightList.findIndex(
            t => t.TabId == saveObj.TabId
          );
          if (currentIndex > -1) {
            //saveObj = this.ConvertDropdown(saveObj);
            var temp = this.ConvertDropdown(this.NewObj(this.InsightList[currentIndex]));
            this.InsightListTemp.push(temp);
          }
          
          if(saveObj.files !== null && saveObj.files !== undefined){
            let formData = new FormData(); // Create FormData for upload file

            saveObj.files.forEach(file => {
              let filename = this.GenerateFileName(file.name); // Create file name
              saveObj.FileNames.push(filename); // Add file name to list
              formData.append(saveObj.InsightNo, file, filename); // Add file for upload
            });

            // Upload File
            this.UploadFile(formData);
          }
          this.hideLoader = false;
          this.ChangeModal();

          // ====> K2
          // if (action === "Submit") {
          //   //console.log("Submit");
          //   this.k2Obj.push({
          //     DocumentNO: saveObj.InsightNo,
          //     CreatorName: saveObj.InsightCreator,
          //     UserCreator: saveObj.CreateByUser,
          //     Action: "Submit"
          //   });
          //   //this.InsightNoList.push("CreatorName", saveObj.InsightCreator);
          //   this.ConfirmK2Service(this.k2Obj);
          //   //submit k2//
          // }
          // else if(action === "Approve"){
          //   //console.log("Approve");
          //   this.k2Obj.push({
          //       Username: this.userprofile.Username,
          //       Token: this.userToken.token,
          //       ProcessID: this.insightProcessID,
          //       Action: action,
          //     });
          //     this.ConfirmK2Service(this.k2Obj);
          // }
          // else{
          //   this.ChangeModal();
          // }
          // ====> K2
        });
    },
    DeleteData: function() {
      if (confirm("Do you want to delete?")) {
        if (this.InsightList.length > 0) {
          let saveObj = this.ConvertDropdown(this.InsightList[0])        
          axios
            .post(URL_Delete, saveObj)
            .then(response => {
              //console.log("response", response);
              //saveObj.FlagDelete = true;
              //this.InsightObject = saveObj;
              if (response.status === 200) {
                this.$emit("save-success");
                this.$_toast_Noti("success", "success", "");
              }
            })
            .catch(error => {
              //console.log("error", error);
              this.$_toast_Noti("error", "error", error);
            })
            .finally(() => {
              //this.ChangeModal();
              this.isshow = false;
              //console.log("isshow",this.isshow);
              //this.InsightList = [];
              //this.item = null;
            });
        }
      }
    },
    UploadFile(formData) {
      // Upload File
      axios
        .post(URL_Upload, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .catch(error => {
          //console.log("error",error);
          this.$_toast_Noti("error", "error", error);
        })
        .finally(() => {
          //console.log("success");
          this.$_toast_Noti("success", "success", "");
          this.ChangeModal();
        });
    },
    newLenTab(tabId) {
      //console.log("NewLenTap tabId",tabId);
      let currentIndex = this.InsightList.findIndex(t => t.TabId == tabId);
      let temp = null;
      //console.log("currentIndex",currentIndex);
      if (currentIndex > -1) {
        temp = this.InsightList[currentIndex];
        //console.log("temp",temp);
      }

      this.IsAddNewTab = false;

      var date = new Date();

      var dateStr =
        date.getFullYear() +
        ("00" + (date.getMonth() + 1)).slice(-2) +
        ("00" + date.getDate()).slice(-2) +
        ("00" + date.getHours()).slice(-2) +
        ("00" + date.getMinutes()).slice(-2) +
        ("00" + date.getSeconds()).slice(-2) +
        date.getMilliseconds();
      let newid =
        dateStr +
        Math.floor(Math.random() * 100) +
        (this.InsightList.length + 1);

      let newobj = this.NewObj(temp);
      newobj.TabId = newid;

      this.InsightList.push(newobj);

      this.IsAddNewTab = true;

      this.UnActiveAddtab();
      this.GoToLastTab();
      setTimeout(
        function() {          
          this.CheckDuplicateTab(newobj);
        }.bind(this),
        10
      );

    },
    CheckDuplicateTab(obj){
      this.IsAddNewTab = true;
      //console.log("CheckDuplicateTab");

      this.UnActiveAddtab();
      this.GoToLastTab();

      setTimeout(
        function() {          
          this.CheckDuplicateTab(newobj);
        }.bind(this),
        10
      );

    },
    CheckDuplicateTab(obj){
      this.IsAddNewTab = true;
      //console.log("CheckDuplicateTab");

      setTimeout(
        function() {

          let classlength = document.querySelectorAll(".usingTabs .nav-link.active").length;

          //console.log("classlength",classlength);
          //console.log("Duplicate Tab ?");
          if (classlength > 1) {

            //console.log("Duplicate Tab");

            let currentIndex = this.InsightList.findIndex(t => t.TabId == obj.TabId);
            this.InsightList.splice(currentIndex, 1);

            this.IsAddNewTab = false;
            var date = new Date();

            var dateStr =
              date.getFullYear() +
              ("00" + (date.getMonth() + 1)).slice(-2) +
              ("00" + date.getDate()).slice(-2) +
              ("00" + date.getHours()).slice(-2) +
              ("00" + date.getMinutes()).slice(-2) +
              ("00" + date.getSeconds()).slice(-2) +
              date.getMilliseconds();
            let newid =
              dateStr +
              Math.floor(Math.random() * 100) +
              (this.InsightList.length + 1);


            obj.TabId = newid;

            this.InsightList.push(obj);
            this.IsAddNewTab = true
            ;
            setTimeout(
              function() {
                
                this.UnActiveAddtab();
                this.GoToLastTab();

              }.bind(this),
              10
            );

          }
          else{
            this.IsAddNewTab = true;
            
          }

        }.bind(this),
        10
      );
    },
    GoToLastTab() {

      //this.IsAddNewTab = false;

      setTimeout(
        function() {

          let lastusingTabs = document.querySelector(
            "#InsightTab" + this.InsightList.length + " a"
          );

          if (lastusingTabs !== null) {
            lastusingTabs.click();
          }

          setTimeout(
            function() {              
              this.IsAddNewTab = true;
                  setTimeout(
                  function() {              
                    this.UnActiveAddtab();
                  }.bind(this),
                10
              );

              }.bind(this),
            10
          );

        }.bind(this),
        10
      );
    },
    UnActiveAddtab() {
      let newtabactive = document.querySelector(".linknewtab .nav-link.active");
      //console.log("newtabactive",newtabactive);
      if (newtabactive !== null){
        newtabactive.classList.remove("active");
        //console.log("remove new tab active",newtabactive);
      }
    },
    closeLenTab(objid) {
      //console.log(objid);
      let currentIndex = this.InsightList.findIndex(t => t.InsightNo == objid);
      this.InsightList.splice(currentIndex, 1);

      this.GoToLastTab();

    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    },
    getShuffledData() {
      return this.shuffleArray(this.InsightList.slice(0));
    },
    UpdateIsSelected(id, event) {
      //console.log("id",id);
      let currentIndex = this.InsightListSubmit.findIndex(t => t.InsightNo == id);
      //console.log("currentIndex",currentIndex);
      if (currentIndex > -1) {
        this.InsightListSubmit[currentIndex].IsSelected = event.target.checked;
      }
    },
    GenerateFileName(filename) {
      return (
        this.$uuid.v4() + "/" + filename // + "." + filename.substr(filename.lastIndexOf(".") + 1)
      );
    },
    GetBu(){
      axios.get(URL_GetBu).then((res) => {
        let options = [{ value: "", label: "-- Select --" }];
        //console.log("res", res);
        if (res !== null) {
          res.data.forEach(function (data) {
            options.push({
              value: data.BUID,
              label: data.BUDesc,
            });
          });
        }
        this.buOptions = options;
      });
    },
    LoadIndustry() {
      axios.get(store.getters.URL + "/api/MasterIndustry/getall").then(res => {
        let Options = [{ value: null, label: "-- Select --" }];
        if (res !== null) {
          res.data.forEach(function(data) {
            Options.push({
              value: data.IndustryID,
              label: data.IndustryDesc
            });
          });
        }
        this.IndustryOptions = Options;
      });
    },
    LoadSegment() {
      axios.get(store.getters.URL + "/api/MasterSegment/getall").then(res => {
        //console.log("MasterSegment",res);
        if (res !== null) {
          this.SegmentList = res.data;
        }
      });
    },
    IndustryOnChange(event, TabId) {
      //console.log("TabId",TabId);

      let Options = [{ value: null, label: "--Select--" }];

      if (event !== null) {
        var Filter = this.SegmentList.filter(function(obj) {
          return obj.IndustryID == event.value;
        });

        if (event.label.toLowerCase() != "others" &&
            Filter != null &&
            Filter.length > 0
        ) {

          let ohterFilter = this.SegmentList.filter(function(obj) {
            return obj.SegmentDesc.toLowerCase() == "others";
          });

          if (ohterFilter != null && ohterFilter.length > 0) {
            Filter.push(ohterFilter[0]);
          }
        }

        Filter.forEach(function(data) {
          Options.push({
            value: data.SegmentID,
            label: data.SegmentDesc
          });
        });
      }

      let currentIndex = this.InsightList.findIndex(t => t.TabId == TabId);
      if (currentIndex > -1) {
        this.InsightList[currentIndex].SegmentOptions = Options;
        this.InsightList[currentIndex].InsightCusSegment = null;
        this.InsightList[currentIndex].SubSegmentOptions = [
          { value: null, label: "--Select--" }
        ];
        this.InsightList[currentIndex].InsightCusSubSegment = null;
      }
    },
    LoadSubSegment() {
      axios
        .get(store.getters.URL + "/api/MasterSubSegment/getall")
        .then(res => {
          //console.log("MasterSegment",res);
          if (res !== null) {
            this.SubSegmentList = res.data;
          }
        });
    },
    SegmentOnChange(event, TabId) {
      let Options = [{ value: null, label: "--Select--" }];
      if (event !== null) {
        var Filter = this.SubSegmentList.filter(function(obj) {
          return obj.SegmentID == event.value;
        });
        if (event.label.toLowerCase() != "others" &&
            Filter != null &&
            Filter.length > 0
        ) {
          let ohterFilter = this.SubSegmentList.filter(function(obj) {
          return obj.SubSegmentDesc.toLowerCase() == "others";
          });

          if (ohterFilter != null && ohterFilter.length > 0) {
            Filter.push(ohterFilter[0]);
          }
        }

        Filter.forEach(function(data) {
          Options.push({
            value: data.SubSegmentID,
            label: data.SubSegmentDesc
          });
        });
      }

      let currentIndex = this.InsightList.findIndex(t => t.TabId == TabId);
      if (currentIndex > -1) {
        this.InsightList[currentIndex].SubSegmentOptions = Options;
        this.InsightList[currentIndex].InsightCusSubSegment = null;
      }
    },
    LoadSourceIndustry() {
      axios.get(store.getters.URL + "/api/MasterSource/getall").then(res => {
        let Options = [{ value: null, label: "-- Select --" }];
        if (res !== null) {
          res.data.forEach(function(data) {
            Options.push({
              value: data.SourceID,
              label: data.SourceDesc
            });
          });
        }
        this.SourceIndustryOptions = Options;
      });
    },
    LoadLens() {
      axios.get(store.getters.URL + "/api/MasterLens/getall").then(res => {
        let Options = [{ value: null, label: "-- Select --" }];
        if (res !== null) {
          this.LenList = res.data;
          //console.log("LenList",this.LenList);
          res.data.forEach(function(data) {
            Options.push({
              value: data.LensID,
              label: data.LensDesc +' - '+ data.LensRemark
            });
          });

        }
        this.LensOptions = Options;
      });
    },
    ConfirmDeleteFileBeforeSave(key, tabId) { 
      if (confirm("Do you want to delete?")) {     
        var currentIndex = this.InsightList.findIndex(t => t.TabId == tabId);   
        if (currentIndex > -1) {
          this.InsightList[currentIndex].files.splice(key, 1)
        }
      }
    },
    ConfirmDeleteFile(file) {
      if (confirm("Do you want to delete?")) {
        file.IsDelete = true;
      }
    },
    ConfirmK2Service(k2Obj) {
      this.hideLoader = true

      //console.log("ConfirmK2Service", k2Obj);

      switch(k2Obj[0].Action){
        case "Submit":
            axios.put(store.getters.URL + "/api/IdeationInsight/submitinsight",k2Obj).then(res => {
              //console.log("res",res);
              if (res.data.StatusCode === 200) {
                if (res.data.Message === "Success") {
                  this.$_toast_Noti("success","success","Your insight was submitted.");
                  this.confirmSubmitInsightModal = false;
                  
                  this.ConfirmIdeaModal = true;
                  this.$emit("save-success");
                  this.ChangeModal()
                }
                else if(res.data.Message === "Error"){
                    this.$_toast_Noti("error","error","Cannot submit insight.Please try again later.!");
                    this.confirmSubmitInsightModal = false;
                }
              }
              else{
                this.$_toast_Noti("error","error",res.data.Message);
              }
              this.hideLoader = false
          })
          .catch((err) => {
              console.error("ErrorRes", err.response);
              this.$_toast_Noti("error","error",err.response);
              this.confirmSubmitInsightModal = false;
              this.hideLoader = false
            });
        break
        case "Approve":
            //console.log("ObjApprove", k2Obj);
            axios.put(store.getters.URL + '/api/IdeationInsight/approveinsight',k2Obj).then(res => {
              //console.log("res",res);
              if (res.data.StatusCode === 200) {

                if (res.data.Message === "Success") {
                  this.$_toast_Noti("success","success","Your insight was approved.");
                  
                  this.confirmApproveInsightModal = false;                  
                  //this.ConfirmConceptModal = true;
                  
                    setTimeout(
                      function() {

                        this.$emit("save-success");
                        this.hideLoader = false
                        this.ChangeModal();

                      }.bind(this),
                      5555 
                    );

                  
                }
                else if(res.data.Message === "Error"){
                    this.$_toast_Noti("error","error","Cannot approve insight.Please try again later.!");
                    this.confirmApproveInsightModal = false;
                    this.hideLoader = false
                }else{
                  this.$_toast_Noti("error","error",res.data.Message);
                  this.confirmApproveInsightModal = false;
                  this.hideLoader = false
                }
                
              }
              else{
                this.$_toast_Noti("error","error",res.data.Message);
                this.confirmApproveInsightModal = false;
                this.hideLoader = false
              }
              
            }).catch(err => {
              this.$_toast_Noti("error","error",err.error);
              this.confirmApproveInsightModal = false;
              this.hideLoader = false
            }) 
          break
          default:
          break;
      }
    },
    K2Action(action) {
      switch (action) {
        case "Submit":
          this.k2Obj = [];
          this.SaveData(action)
          //this.confirmSubmitInsightModal = true;
          //this.ConfirmIdeaModal = true
          break;
        case "Approve":
          this.k2Obj = [];
          this.confirmApproveInsightModal = true;
          break;
        case "Reject":
          this.rejectModel = { 
            DocumentNo : this.insightNo,
            ProcessID : this.insightProcessID,
            RejectLevel : "Manager",
            RejectUser : this.userprofile.DisplayName,
            RejectComment : ""
           };
          this.showRejectModal = true;
          break;
        default:
          break;
      }
    },
    CancelSubmit() {
      this.confirmSubmitInsightModal = false;
      //this.ConfirmIdeaModal = true;
    },
    CancelApprove(){
      this.confirmApproveInsightModal = false;
    },
    InsightLensOnChange(event){
      //console.log("event",event);
      if(event !== null && event !== undefined){
        let currentIndex = this.LenList.findIndex(
          t => t.LensID == event.value
        );
        
        if (currentIndex > -1) {
          //console.log("this.LenList[currentIndex]",this.LenList[currentIndex]);
          this.LensRemark = this.LenList[currentIndex].LensRemark;
          this.LensRemark2 = this.LenList[currentIndex].LensRemark2;
          this.LensRemark3 = this.LenList[currentIndex].LensRemark3;

          // console.log("LensRemark1", this.LensRemark1)
          // console.log("LensRemark2", this.LensRemark2)
          // console.log("LensRemark3", this.LensRemark3)
        }
        else{
          this.LensRemark = "";
          this.LensRemark2 = "";
          this.LensRemark3 = "";
        }
      }
      else{
        this.LensRemark = "";
        this.LensRemark2 = "";
        this.LensRemark3 = "";
      }

    },
    CheckIndustrySelect ()
    {
      this.$nextTick(() => {
        let index = 0;
        Array.from(this.InsightList).forEach(item => {
            //console.log('index', index)
            let name1 = 'RefVSelect' + index
            let name2 = 'RefVSelectSourceOfInsight' + index
            let name3 = 'RefVSelect4Lens' + index
            let name4 = 'RefVSelectBU' + index
            //console.log('name', name1)
            if(this.$refs[name1] == undefined || this.$refs[name2] == undefined 
               || this.$refs[name3] == undefined || this.$refs[name4] == undefined)
            {
              //console.log('undefined')
              return;
            }
            if(item.InsightCusIndustry == null)
            {
              this.$refs[name1][0].$el.children[0].style.borderColor = "red";
            }
            else
            {
              this.$refs[name1][0].$el.children[0].style.borderColor = "";
            }

            if(item.InsightSourceIndustry == null)
            {
              this.$refs[name2][0].$el.children[0].style.borderColor = "red";
            }
            else
            {
              this.$refs[name2][0].$el.children[0].style.borderColor = "";
            }

            if(item.InsightLens == null)
            {
              this.$refs[name3][0].$el.children[0].style.borderColor = "red";
            }
            else
            {
              this.$refs[name3][0].$el.children[0].style.borderColor = "";
            }
            if(item.BU == null)
            {
              this.$refs[name4][0].$el.children[0].style.borderColor = "red";
            }
            else
            {
              this.$refs[name4][0].$el.children[0].style.borderColor = "";
            }
            index++
        });
      });
    },
    AddRedStarToElement(){
      this.$nextTick(() => {
        let index = 0;
        const tempRedStar = '<span class="error">*</span>';
        Array.from(this.InsightList).forEach(item => {
          let nameSource ='TextAreaSourceDetail' + index;
          let nameDetail = 'TextAreaDetail' + index
          //console.log(nameSource)
          //console.log(nameDetail)
          if(this.$refs[nameSource] == undefined || this.$refs[nameDetail] == undefined)
          {
            //console.log('undefined')
            return;
          }
          
          let Shtml = this.$refs[nameSource][0].$children[0].$el.children[0].innerHTML;
          let Dhtml = this.$refs[nameDetail][0].$children[0].$el.children[0].innerHTML;
          
          if(!Shtml.includes('</span>'))
          {
            this.$refs[nameSource][0].$children[0].$el.children[0].innerHTML = `${Shtml} ${tempRedStar}`
          }
          if(!Dhtml.includes('</span>'))
          {
            this.$refs[nameDetail][0].$children[0].$el.children[0].innerHTML = `${Dhtml} ${tempRedStar}`
          }
          index++
        });
      });
      
    },
  },
  computed: {
    countTabLens: function() {
      return this.InsightList.length;
    },
    isshow: {
      get() {
        return this.showModal;
      },
      set(v) {
        this.$emit("InsightModal-updated", v);
      }
    },
    InsightObject: {
      get() {
        return this.item;
      },
      set(v) {
        this.$emit("InsightObject-updated", v);
      }
    },
    userRole: function () {
      return this.userprofile.Role;
    }
    
  },
  watch: {    
    showModal: function(newVal, oldVal) {
      if(newVal == true)
      {
        this.CheckIndustrySelect();
        this.AddRedStarToElement();
      }
    },
    InsightList: {
      handler: function(newValue) {
        this.CheckIndustrySelect();
        this.AddRedStarToElement();
      },
      deep: true
    },
    isshow: function(newVal, oldVal) {
      this.GoToLastTab();
    },
    item: function(newVal, oldVal) {
      // watch it
      //console.log('item', item)
      //console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      if (newVal !== null) {

        setTimeout(
          function() {
            //console.log('LoadById');
            this.InsightList = [];
            this.LoadById(newVal.InsightNo);
            this.insightNo = newVal.InsightNo;
            this.insightProcessID = newVal.InsightProcessID;        
            this.viewmode === "view" && this.SetViewMode(true);
          }.bind(this),
          1000
        );

      }
      //console.log("this.insightProcessID",this.insightProcessID);
    }
  }
};
</script>

<style>
.cpl-10 {
  padding-left: 10vw;
  padding-top: 1vw;
}

.LensRemark{
  line-height: 0px;
}

.LensRemark2{
  line-height: 20px;
  padding-top: 0px;
}

.myerror{
  border: 10px !important;
  border-color: red !important;
}

</style>
